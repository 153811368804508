.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container {
  position: relative;
}

.logo-container {
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-circle {
  position: absolute;
  border: 5px solid #fff;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s linear infinite;
}

.splash-logo {
  width: 80%;
  height: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
