.drop-file-input {
  position: relative;
  border: 2px dashed #545758;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label p {
  margin: 0;
}

.drop-file-input__label img {
  height: 30px;
}

.drop-file-preview p {
  font-weight: 500;
  margin-bottom: 5px;
}

.drop-file-preview__title {
  margin-bottom: 10px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  background-color: #dfdfdf;
  padding: 10px;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
}

.drop-file-preview__item img {
  width: 40px;
  margin-right: 10px;
  object-fit: cover;
  height: min-content;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: #be6e6e;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: #393a3a;
  cursor: pointer;
}
