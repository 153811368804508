


.menu {
    display: flex;
    color: white;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
    margin-left: -5px !important;
}

.link_text {
    white-space: nowrap;
    font-size: 15px;
}

.menu a  *{
    color: black;
}
