.renderer-container {
  max-width: 800px;
  margin: 0 auto;
}

.video-element {
  width: 100%;
  height: auto;
  max-height: 100%;
}
